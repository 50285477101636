.main__wrapper .campaing__title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 25px 0 25px 0px;
}


.idea__card {
    border-radius: 18px;
    background-color: var(--white);
    border: solid 1px var(--common-border);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(224, 228, 231, 0.65);
    -moz-box-shadow: 0px 2px 10px 0px rgba(224, 228, 231, 0.65);
    box-shadow: 0px 2px 10px 0px rgba(224, 228, 231, 0.65);
}

.main__wrapper .idea__card.access__messages {
    width: 100%;
    margin: 3rem auto;
    padding: 25px;
    text-align: center;
}

.main__wrapper .idea__card.access__messages img {
    max-width: 120px;
    margin-bottom: 35px;
}

.main__wrapper .idea__card.access__messages {
    width: 100%;
    margin: 3rem auto;
    padding: 25px;
    text-align: center;
}

.main__wrapper .idea__card.access__messages.denied h3.access__title {
    font-size: var(--main-title);
    margin-bottom: 20px;
    /* color: var(--bloody-red); */
    font-weight: bold;
}

.main__wrapper .idea__card.access__messages.denied h6.access__description {
    font-size: var(--sub-title);
    font-weight: 400;
    margin-bottom: 20px;
}

.main__wrapper .idea__card.access__messages.success h6.access__description {
    font-size: var(--sub-title);
    font-weight: 400;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .main__wrapper .idea__card.access__messages {
        width: 45%;
        margin: 5rem auto;
        padding: 40px;
        text-align: center;
    }
}

.account-creation.ac-status {
    background-color: #cacaca 26;
    height: 100vh;
    width: 100vw;
    font-family: sans-serif;
}

.account-creation.ac-status .ac-status__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 35rem;
    text-align: center;
    margin: 0 auto;
}

.account-creation.ac-status .ac-status__wrapper .ac-status-animation__wrapper {
    margin-bottom: 1.5rem;
    position: relative;
}

.account-creation.ac-status .ac-status__wrapper .title {
    margin-bottom: 1.2rem;
}

.feedback__card {
    width: 800px;
    margin: auto;
    padding: 40px 20px;
}

@media screen and (max-width: 768px) {
    .feedback__card {
        width: 97%;
        margin: auto;
        padding: 40px 20px;
    }
}

question__Wrapper {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 20px 0 20px;
}

.question__Wrapper .q-title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 35px;
    font-size: 16px;
}

.question__Wrapper:last-child hr {
    display: none;
}

.reaction__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.emoji__container {
    padding: 20px 30px;
    border-radius: 4px;
    transition: 0.3s;
    max-width: 120px;
    cursor: pointer;
}

.emoji__container:hover,
:focus {
    /* background-color: #FFF2D2; */
    transition: 0.3s;
}

.emoji__container.active {
    background-color: #FFF2D2;
    transition: 0.3s;
    font-weight: 700;
}

.emoji__wrapper img {
    width: 55px;
    display: block;
    margin: 0 auto;
}

.emoji__wrapper p {
    text-align: center;
    font-size: 12px;
    margin-top: 25px;
}

.btn-main-action {
    width: 180px;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.text-success {
    color: #00c851 !important;
}

footer {
    text-align: center;
    position: inherit;
    margin-top: 70px;
    bottom: 0;
    width: 100%;
    border-top: solid 1px var(--common-border);
    padding: 15px 0 15px;
    font-size: var(--footer-mini);
}

footer .copy-rights .svg__wrapper {
    display: block;
    padding-right: unset;
    text-align: center;
    margin-bottom: 10px;
}

footer .copy-rights .svg__wrapper img {
    margin-left: auto;
    margin-right: auto;
    max-height: 15px;
}

footer .col-md-6:nth-child(2) {
    text-align: center;
    margin-top: 15px;
}

footer ul.policies {
    text-align: right;
    display: inline-flex;
    list-style: none;
}

footer ul.policies li {
    padding-left: 40px;
}

footer ul.policies li:first-child {
    padding-left: 0;
}

footer ul.policies li a {
    color: var(--primary-font__color);
}

footer ul.policies li a:hover {
    text-decoration: underline;
}


/* Responsive - Desktop view */

@media screen and (min-width: 768px) {
    footer {
        text-align: left;
        position: inherit;
        margin-top: 100px;
        bottom: 0;
        width: 100%;
        border-top: solid 1px var(--common-border);
        padding: 25px 0 25px;
        font-size: var(--footer-mini);
    }

    footer .copy-rights .svg__wrapper {
        display: inline-block;
        padding-right: 25px;
    }

    footer .col-md-6:nth-child(2) {
        text-align: right;
        margin-top: unset;
    }

    footer .copy-rights .svg__wrapper {
        margin-bottom: 0;
    }

    .main__wrapper .campaing__title {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin: 75px 0 25px
    }
}